@import '~antd/dist/antd.less';

.logo {
    height: 32px;
    line-height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
    text-align: center;
}

.site-layout .site-layout-background {
    background: #fff;
}
